// Generated by Framer (7aa0232)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["xU3gEQGBs", "UT3NI0jQR", "ETnzwtWv8"];

const variantClassNames = {ETnzwtWv8: "framer-v-13gtgys", UT3NI0jQR: "framer-v-w3rgg5", xU3gEQGBs: "framer-v-qjwhjj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"L / Success": "xU3gEQGBs", "M / Success": "UT3NI0jQR", "S / Success": "ETnzwtWv8"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "xU3gEQGBs"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "xU3gEQGBs", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-LsUM0", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-qjwhjj", className)} data-framer-name={"L / Success"} layoutDependency={layoutDependency} layoutId={"xU3gEQGBs"} ref={ref} style={{backgroundColor: "var(--token-0dc4c26f-f3d9-46d4-b4db-4633e1b3b583, rgb(34, 197, 94))", borderBottomLeftRadius: 999, borderBottomRightRadius: 999, borderTopLeftRadius: 999, borderTopRightRadius: 999, ...style}} {...addPropertyOverrides({ETnzwtWv8: {"data-framer-name": "S / Success"}, UT3NI0jQR: {"data-framer-name": "M / Success"}}, baseVariant, gestureVariant)}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-LsUM0 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-LsUM0 .framer-1arya37 { display: block; }", ".framer-LsUM0 .framer-qjwhjj { height: 24px; overflow: hidden; position: relative; width: 24px; will-change: var(--framer-will-change-override, transform); }", ".framer-LsUM0.framer-v-w3rgg5 .framer-qjwhjj { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 20px); width: 20px; }", ".framer-LsUM0.framer-v-13gtgys .framer-qjwhjj { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 16px); width: 16px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"UT3NI0jQR":{"layout":["fixed","fixed"]},"ETnzwtWv8":{"layout":["fixed","fixed"]}}}
 */
const FramerPIWN_javV: React.ComponentType<Props> = withCSS(Component, css, "framer-LsUM0") as typeof Component;
export default FramerPIWN_javV;

FramerPIWN_javV.displayName = "Base/Avatar Status";

FramerPIWN_javV.defaultProps = {height: 24, width: 24};

addPropertyControls(FramerPIWN_javV, {variant: {options: ["xU3gEQGBs", "UT3NI0jQR", "ETnzwtWv8"], optionTitles: ["L / Success", "M / Success", "S / Success"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerPIWN_javV, [])